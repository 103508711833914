import React, {memo, useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import ProductCard from '../../components/pricing/productCard';
// import Counter from '../../components/layout/header/components/counter';
import Layout from '../../components/layout';
import Section from '../../components/section';
// import {UserContext} from '../../contexts/user-context';
import {UserContext} from '../../contexts/user-context';
import {useMediaQuery} from 'react-responsive';
import Switch from '../../components/switch';
import {closeIcon, twoMonthsFreeIcon} from '../../images/svgIcons';
import pricingIcon from '../../images/payments/pricing-hint.png';
// import {Link} from 'gatsby';
// import {useQuery} from '@apollo/client';
// import {HEXOSPARK_USER} from '../../graphql/queries';
import './styles.scss';
import {getPackageInfo} from '../../helpers';
import {navigate} from 'gatsby';

const Pricing = ({pageContext}: any) => {
  //------------------State---------------------
  // const [id] = useState(
  //   pageContext?.products.find((item: any) => item.productType === 'STRIPE' && item.name !== 'FREE').id,
  // );
  const [plans, setPlans] = useState({
    annual: null as any,
    monthly: null as any,
    ltd: null as any,
  });
  const [disableAll, setDisableAll] = useState(false);
  // const [paypal_id] = useState(pageContext?.products.find((item: any) => item.productType === 'PAYPAL').id);
  // const [price] = useState(pageContext?.products.find((item: any) => item.productType === 'PAYPAL').amount);
  const {user, hexosparkUser} = useContext(UserContext);
  const [annual, setAnnual] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentPlanInterval, setCurrentPlanInterval] = useState('');
  const [paypalSubscriptionId, setPaypalSubscriptionId] = useState('');
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [ltd, setLtd] = useState(false);

  const [trialPackagesInfo, setTrialPackagesInfo] = useState({can_use_trial: true, trial_packages: ['']});

  // const {data: hexosparkUserData} = useQuery(HEXOSPARK_USER, {
  //   fetchPolicy: 'no-cache',
  // });

  // const [expirationDate, setExpariationDate] = useState(null as any);
  // const [expired, setExpired] = useState(false);
  // const [paidUser, setPaidUser] = useState(false);

  useEffect(() => {
    const annualStripePlans = pageContext?.products?.filter(
      (item: any) => item.productType === 'STRIPE' && item.name !== 'FREE' && item.interval === 'ANNUAL',
    );
    const monthlyStripePlans = pageContext?.products?.filter(
      (item: any) => item.productType === 'STRIPE' && item.name !== 'FREE' && item.interval === 'MONTHLY',
    );

    const annualPlansWithPaypalId = annualStripePlans?.map((item: any) => {
      const annualPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' && sub.name !== 'FREE' && sub.interval === 'ANNUAL' && sub.name === item.name,
      );
      return {...item, paypal_id: annualPaypal?.id};
    });

    const monthlyPlansWithPaypalId = monthlyStripePlans?.map((item: any) => {
      const monthPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' && sub.name !== 'FREE' && sub.interval === 'MONTHLY' && sub.name === item.name,
      );
      return {...item, paypal_id: monthPaypal?.id};
    });

    const ltdStripe = pageContext?.products?.filter(
        (item: any) =>
          item.productType === 'STRIPE' && item.name !== 'FREE' && item.name !== 'PAID' && item.interval === 'LTD',
      )
      .sort((obj1: any, obj2: any) => {
        if (obj1.amount > obj2.amount) {
          return 1;
        } else {
          return -1;
        }
      });

    const ltdWithPaypalID = ltdStripe?.map((item: any) => {
      const ltdPaypal = pageContext?.products?.find(
        (sub: any) =>
          sub.productType === 'PAYPAL' &&
          sub.name !== 'FREE' &&
          sub.name !== 'PAID' &&
          sub.interval === 'LTD' &&
          sub.name === item.name,
      );
      return {...item, paypal_id: ltdPaypal?.id};
    });

    const finArrAnnual = annualPlansWithPaypalId?.map((item: any) => {
      if (
        (paypalSubscriptionId && currentPlan === 'TEAM') ||
        (currentPlanInterval === 'LTD' && item.interval !== 'LTD') ||
        (currentPlan !== 'FREE' && item.name === 'SOLO')
      ) {
        return {...item, disabled: true};
      } else {
        return item;
      }
    });
    const finArrMonth = monthlyPlansWithPaypalId?.map((item: any) => {
      if (
        (paypalSubscriptionId && currentPlan === 'TEAM') ||
        (currentPlanInterval === 'LTD' && item.interval !== 'LTD') ||
        currentPlanInterval === 'ANNUAL' ||
        (currentPlan !== 'FREE' && item.name === 'SOLO')
      ) {
        return {...item, disabled: true};
      } else {
        return item;
      }
    });
    const diffPrice = (ltdWithPaypalID?.find((item: any) => item.name === 'TEAM')?.amount || 0) - (ltdWithPaypalID?.find((item: any) => item.name === 'SOLO')?.amount || 0);
    const finArrLtd = ltdWithPaypalID?.map((item: any) => {
      if (
        currentPlan === 'PAID' ||
        ((currentPlan === 'SOLO' || currentPlan === 'TEAM') && currentPlanInterval === 'LTD' && item.name === 'SOLO') ||
        (currentPlanInterval !== 'LTD' && currentPlan !== 'FREE')
      ) {
        return {...item, disabled: true, amount: currentPlan === 'SOLO' && currentPlanInterval === 'LTD' && item.name === 'TEAM' ? diffPrice : item.amount};
      } else {
        return {...item, amount: currentPlan === 'SOLO' && currentPlanInterval === 'LTD' && item.name === 'TEAM' ? diffPrice : item.amount};
      }
    });

    const monthNothDisabledArr = finArrMonth?.filter((item: any) => !item.disabled);
    const annualNothDisabledArr = finArrAnnual?.filter((item: any) => !item.disabled);
    const ltdNothDisabledArr = finArrLtd?.filter((item: any) => !item.disabled);
    // const ltd = user?.id && user?.promoData?.hexowatch_ltd;
    const ltd = false;
    if (ltd || !monthNothDisabledArr?.length) {
      setAnnual(true);
    }
    setDisableAll(currentPlan === 'PAID' ? true : ltd ? !ltdNothDisabledArr : !annualNothDisabledArr?.length);
    setContactModalOpen(currentPlan === 'PAID' ? true : ltd ? !ltdNothDisabledArr : !annualNothDisabledArr?.length);
    setPlans({
      annual: finArrAnnual,
      monthly: finArrMonth,
      ltd: finArrLtd,
    });
    setLtd(!!ltd);
  }, [pageContext, currentPlan, currentPlanInterval, paypalSubscriptionId, user]);

  // useEffect(() => {
  //   if (
  //     currentPlanInterval &&
  //     currentPlan &&
  //     user &&
  //     user.id &&
  //     plans.annual &&
  //     !disableAll &&
  //     localStorage.getItem('fromLogonOrRegistrationPage') &&
  //     getPackageInfo() &&
  //     !paypalSubscriptionId
  //   ) {
  //     const selected = JSON.parse(localStorage.getItem('checkout-data') || '');
  //     const checkItem = (selected.annual ? plans.annual : plans.monthly).find((el: any) => el.id === selected.id);
  //     if (checkItem) localStorage.removeItem('fromLogonOrRegistrationPage');
  //     if (!checkItem.disabled) {
  //       navigate('/pricing/checkout');
  //     }
  //   }
  // }, [plans]);
  // useEffect(() => {
  // if (hexosparkUser?.invitationInfo?.invited_at) {
  // const date = new Date(hexosparkUser?.invitationInfo?.invited_at).setTime(
  //   new Date(hexosparkUser?.invitationInfo?.invited_at).getTime() + 72 * 60 * 60 * 1000,
  // );
  //   if (new Date(date).getTime() > new Date().getTime()) {
  //     setExpariationDate(new Date(date));
  //   } else {
  //     setExpired(true);
  //     setExpariationDate(null);
  //   }
  // } else {
  //   setExpired(false);
  //   setExpariationDate(null);
  // }
  // if (hexosparkUser?.hsUser?.pricing_package && hexosparkUser?.hsUser?.pricing_package !== 'FREE') {
  //   setPaidUser(true);
  // } else {
  //   setPaidUser(false);
  // }
  //}, [hexosparkUser]);

  useEffect(() => {
    if (hexosparkUser?.hsUser && typeof hexosparkUser.hsUser.pending_payment === 'boolean') {
      setPendingPayment(hexosparkUser.hsUser.pending_payment);
    } else {
      setPendingPayment(false);
    }
    setCurrentPlan(hexosparkUser?.hsUser?.pricing_package || 'FREE');
    setCurrentPlanInterval(hexosparkUser?.hsUser?.pricing_package_interval || '');
    setPaypalSubscriptionId(hexosparkUser?.hsUser?.paypal_subscription_id || '');
    if (hexosparkUser?.hsUser?.id) {
      setTrialPackagesInfo({
        trial_packages: hexosparkUser.trial_packages,
        can_use_trial: hexosparkUser.can_use_trial,
      });
    } else if (hexosparkUser?.hsUser)
      setTrialPackagesInfo({
        trial_packages: [''],
        can_use_trial: true,
      });
  }, [hexosparkUser]);

  useEffect(() => {
    if ((currentPlanInterval === 'LTD' && !ltd) || currentPlan === 'PAID') setContactModalOpen(true);
  }, [currentPlanInterval, ltd, currentPlan]);

  const isMobile = useMediaQuery({maxWidth: 1250});
  //------------------Query---------------------
  return (
    <Layout>
      <Section pricing light pagehero pageheroPading lgPd>
        <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Hexospark Pricing Plans</h1>

        {/* <p className="f_300 page-hero-subTitle pricing-p-hero ">
                  We want to involve our community as early as possible in the development process so we can build our
                  next-gen email outreach platform tailored to your needs.
                </p> */}

        {/* {expirationDate && !paidUser ? (
          <div>
            <div className="text-center">
              Hexospark is currently in beta.
            </div>
          </div>
        ) : !paidUser ? (
          <div className="text-center">
            <div>
              Hexospark is currently in beta.
            </div>
            {user && !user.id && (
              <strong>
                If you are already invited please <Link to="/#login">login</Link> to access the offer.
              </strong>
            )}
          </div>
        ) : (
          <></>
        )} */}
        {/* <div className="text-center f_size_24" style={{color: '#516077'}}>
          Special launch 🚀 offer, get lifetime access for just <span className="line-through-span">$29/monthly</span>{' '}
          $69 one-time payment.
        </div> */}
        {/* <div className="mt-4">
          <Counter date={'Dec 26 2022 07:00:00 GMT'} title="Lifetime deal ends in" titleColor="#00042a" />
        </div> */}
      </Section>
      <div className="pricing-wrapper" id="early-access">
        {/* <h5 className="color-strong">
        To do this, we are opening 3000 pre-launch, early access LTD seats for 7 days only.
      </h5> */}
        {/* <p className="text-center mt-2 d-xl-none">
        Because this is a pre-launch, we are extending our standard refund period to 60 days from the date of the soft
        launch in August.
      </p> */}
        <div className="d-flex position-relative pricing-section-header justify-content-center m-auto">
          <Switch
            text1="Monthly"
            text2="Annually"
            active={!annual}
            onChange={(val: string) => setAnnual(val === 'Annually')}
            className="m-auto m-xl-0 mr-xl-auto"
          />
          <div className="d-flex align-items-center justify-content-center get-month position-absolute">
            {twoMonthsFreeIcon}
          </div>
        </div>
        {contactModalOpen && (
          <div className="text-center all-disabled mt-4">
            <span onClick={() => setContactModalOpen(false)}>{closeIcon}</span>
            <img src={pricingIcon} alt="icon" />
            <div>Need to upgrade your plan? </div>
            <div className="support">
              Please contact{' '}
              <a
                href="mailto:support@hexospark.com"
                className="clickable-text"
                aria-label={'mail'}
                target="_blank"
                rel="noopener"
              >
                support@hexospark.com
              </a>
              {'.'}
            </div>
          </div>
        )}
        <div className="d-flex products-wrapper justify-content-center">
          {!pageContext ? (
            <div className="w-100 d-flex">
              <div className="spinner-border spinner-color m-auto" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : null}
          {(annual ? (ltd ? plans.ltd : plans.annual) : plans.monthly)?.map((item: any, index: number) => (
            <ProductCard
              id={item.id}
              paypal_id={item.paypal_id}
              color={item.color}
              stack={false}
              //count={parseInt(item.name.replace('PAID_', ''))}
              key={item.name}
              price={item.amount}
              expirationDate={true}
              pendingPayment={pendingPayment}
              user={!!(user && user.id)}
              verified={!!user?.verified}
              annual={annual}
              ltdUser={currentPlanInterval === 'LTD'}
              disabled={item.disabled || disableAll}
              name={item.name === 'SOLO' ? 'Solo' : 'Team'}
              canUseTrial={
                !ltd &&
                !annual &&
                ((!user?.id && item.name === 'SOLO') ||
                  (trialPackagesInfo.can_use_trial && trialPackagesInfo.trial_packages.includes(item.name)))
              }
              ltd={ltd && annual}
              monthlyPrice={plans.monthly[index]?.amount}
              annualPrice={plans.annual[index]?.amount}
            />
          ))}
          {plans.monthly && (
            <ProductCard stack={false} expirationDate={true} pendingPayment={pendingPayment} name="Enterprise" />
          )}
        </div>

        <p className={isMobile ? 'text-center mt-1' : 'text-center mt-5'}>
          To use Hexospark you will need an existing email account. SMTP and native Gmail integrations are
          supported.
        </p>
      </div>
      {/* <Section dark className="">
        <div className="row px-0 mx-auto subscribe-section">
          <div className="col-lg-12 text-center">
            <h3 className="notified">Want to be notified as soon as we launch?</h3>
            <p>Sign up to our notification list to be the first to know when Hexospark launches later this year.</p>
            <Subscribe btnText="LET ME KNOW" />
          </div>
        </div>
      </Section> */}
    </Layout>
  );
};

export default memo(Pricing);
